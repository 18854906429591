import React, { useState } from 'react';
import fetch from "node-fetch";
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const IndexPage = () => {
  const [userName, setUserName] = useState('');
  const [roomName, setRoomName] = useState('');
  const navigate = useNavigate();

  const handleUserNameChange = (event) => {
    setUserName(event.target.value);
  };

  const handleRoomNameChange = (event) => {
    setRoomName(event.target.value);
  };

  const createRoom = async () => {
    if (!userName || !roomName) return; // Ensure both userName and roomName are not empty
    console.log("Making a room");
    try {
      const response = await fetch('https://d5libesx21.execute-api.eu-west-2.amazonaws.com/V1/generate/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ roomName }), // Include userName and roomName in the request body
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      //console.log('Response', data);
      //const body = JSON.parse(data);
     //console.log('Room created with ID:', data.roomId);
      console.log("Navigating to", `/room/${data.roomId}?name=${encodeURIComponent(userName)}`);
      navigate(`/room/${data.roomId}?name=${encodeURIComponent(userName)}`);
    } catch (error) {
      console.error('Error creating room:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box textAlign="center" my={4}>
        <img src="/assets/logo.png" alt="Dungeon Master GPT Logo" style={{ maxWidth: '200px', margin: 'auto' }} />
        <Typography variant="h4" gutterBottom>
          Welcome to Dungeon Master GPT
        </Typography>
        <TextField
          label="Enter room name"
          variant="outlined"
          value={roomName}
          onChange={handleRoomNameChange}
          margin="normal"
          fullWidth
          className="textField"
        />
        <TextField
          label="Enter your name"
          variant="outlined"
          value={userName}
          onChange={handleUserNameChange}
          margin="normal"
          fullWidth
          className="textField"
        />
        <Button 
          variant="contained" 
          color="primary" 
          onClick={createRoom} 
          disabled={!userName || !roomName}
          fullWidth
          style={{
            backgroundColor: !userName || !roomName ? '#37474F' : '', // Disabled background color
            color: !userName || !roomName ? '#B0BEC5' : '', // Disabled text color
          }}
        >
          Create a room
        </Button>
      </Box>
    </Container>
  );
};

export default IndexPage;
