import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import IndexPage from './IndexPage'; // Adjust the import path based on your file structure
import RoomDetail from './RoomDetail';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/room/:id" element={<RoomDetail />} />
      </Routes>
    </Router>
  );
}

export default App;
